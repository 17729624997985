<template>
  <el-container>
    <el-main style="overflow: auto !important">
      <pro-document-breadcrumbs
        :breadcrumbs="breadcrumbs"
        user-role="procurer"
      />
      <div class="q-pa-md">
        <pro-form-render
          mode="Edit"
          :code="WorkflowCode"
          :form-data-promise="formDataPromise()"
          @pro-form-submit="
            () => this.$router.push({ name: 'SupplierManagementList' })
          "
        />
      </div>
    </el-main>
  </el-container>
</template>

<script>
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs";
import ProFormRender from "@/components/PROSmart/FormBuilder/ProFormRender";

export default {
  components: { ProDocumentBreadcrumbs, ProFormRender },
  data() {
    return {
      breadcrumbs: [
        {
          name: "menu.SupplierManagement",
          icon: "PROSmart-Account",
          to: { name: "SupplierManagementList" },
        },
        {
          name: "menu.EditSupplierStatus",
        },
      ],
      WorkflowCode: "P_T_EditStatus",
    };
  },
  methods: {
    formDataPromise() {
      return new Promise((resolve) => {
        let obj = { params: this.$route.params };
        resolve(obj);
      });
    },
  },
};
</script>
